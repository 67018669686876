:root {
  --main-bg-color: rgb(58, 202, 207);
}

/*### 1-  Genaral CSS ###*/
body {
  background-color: #ffffff !important;
} /*f9fafb*/
::-webkit-scrollbar {
  width: 7px !important;
  height: 0px !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a9acb0;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #595a5c;
  border-radius: 5px;
}
a {
  text-decoration: none !important;
}

/* Sepesific Scroll Bar*/
.spesific-commp::-webkit-scrollbar {
  width: 20px !important;
}
.spesific-commp::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}
.spesific-commp::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 5px !important;
}

/* Sepesific Scroll Bar*/
.spesific-commp-2::-webkit-scrollbar {
  width: 13px !important;
}
.spesific-commp-2::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}
.spesific-commp-2::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 5px !important;
}

@font-face {
  font-family: "Droid";
  src: url("../public/Fonts/Droid.ttf");
  font-size: 8px !important;
}
body,
h1,
h2,
h3,
h4,
h5,
input,
Select,
option,
button,
Table,
a,
TableCell,
.Toastify__toast,
.font-droid {
  font-family: "Droid", tahoma !important;
}
/*LogIn*/
.login-caontainer {
  padding-top: 40px;
}
.w-login-input {
  width: 60%;
}
@media only screen and (max-width: 768px) {
  .w-login-input {
    width: 100%;
  }
}

/*navbar images*/
.navBar-img {
  border-radius: 20px !important;
}

/*bootsrap icons*/
.bi-sm {
  font-size: 20px;
}
.bi-lg {
  font-size: 50px;
}
.bi-md {
  font-size: 30px;
}
.bi-xlg {
  font-size: 100px;
}
.bi-profile {
  font-size: 35 !important;
}
/*boostrap modification*/
btn:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 #ddd;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none !important;
}
.border-d-sm {
  border-radius: 10px !important;
}
.border-d-md {
  border-radius: 30px !important;
}
.border-d-lg {
  border-radius: 50px !important;
}

/*system*/
.navshadow {
  -webkit-box-shadow: 1px 1px 6px 1px gray !important;
  -moz-box-shadow: 1px 1px 6px 1px gray;
  box-shadow: 1px 1px 6px 1px gray;
}
.abyedh-list {
  color: var(--main-bg-color) !important;
  font-size: 15px;
}
.abyedh-list-a-s {
  border-radius: 3px 3px 3px 3px !important;
  color: var(--main-bg-color) !important;
}
.abyedh-list-a {
  background-color: #dedfdf !important;
  border-radius: 3px 3px 3px 3px !important;
  color: rgb(41, 41, 41) !important;
  font-size: 15px;
}
.btn-system {
  border-radius: 5px !important;
  margin-right: 10px;
}
.start-page-loader {
  z-index: 1101;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}
.btn-imprimer {
  background-color: #08a87d !important;
  color: white !important;
}
.btn-regler {
  background-color: #b3126d !important;
  color: white !important;
}
.text-system {
  color: #1560bd !important;
}

/*bootstrap nav */
.tab-content {
  border-top: 0px rgb(211, 211, 211) solid;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #e0eeff !important;
  border-bottom: 3px solid rgb(92, 110, 214) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: rgb(92, 110, 214) !important;
}

/*resonsive bar*/
.responsive-bar {
  white-space: nowrap !important;
  overflow-x: auto !important;
  overflow-y: none !important;
}
.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.active.item {
  border-color: var(--main-bg-color) !important;
}
.rounded-system {
  border-radius: 20px;
}
.form-check-input {
  width: 35px !important;
  height: 17px !important;
}
.form-check-input:checked {
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
}
.sub-sys-round {
  border-radius: 15px !important;
}
.main-big-card {
  background-image: linear-gradient(
    to right top,
    var(--main-bg-color),
    #ffffff
  );
  border: 0px solid;
}
.w-100-seach-input input,
.w-100-seach-input {
  width: 100% !important;
}
.icon-nav-div {
  background-color: white;
  color: var(--main-bg-color);
  margin-right: 5px;
  text-align: center;
  padding: 3px;
  padding-left: 5px;
}
.outer-for-subnav {
  white-space: nowrap !important;
  overflow-x: scroll !important;
}
.rounded-message-s {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: auto !important;
  text-align: justify;
}
.rounded-message-r {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: auto !important;
  text-align: justify;
}
.elevation {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  border-radius: 3px !important;
}
.border-div {
  border-radius: 10px !important;
}
.border-div-main {
  border-radius: 20px !important;
}
.w-100-grijs-table {
  width: "auto" !important;
}
@media only screen and (min-width: 768px) {
  .w-100-grijs-table {
    width: 100% !important;
  }
}

/*map-height*/
.map-height {
  height: 250px !important;
}
.map-height-lg {
  height: 440px !important;
}
.map-height-md {
  height: 900px !important;
}
.map-height-taxi {
  height: 150px !important;
}
.bg-system-btn {
  background-color: var(--main-bg-color) !important;
  color: white !important;
}
.w-cal-in {
  width: 26px !important;
  text-align: center !important;
  background-color: #06c436 !important;
}
.w-cal-out {
  width: 26px !important;
  text-align: center !important;
  background-color: #c40636 !important;
}
.fc-day-span {
  background-color: red !important;
}
.system-color {
  color: var(--main-bg-color) !important;
}

/* crad container */
.upper {
  height: 100px;
}
.upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.img-card-container {
  position: relative;
}
.mcbg {
  height: 90px !important;
  width: 100% !important;
  margin-top: 2px;
  border-radius: 10px 10px 0 0;
}
.card-container {
  position: absolute;
  top: -50px;
  left: 38%;
  height: 90px;
  width: 90px;
  border: 5px solid #fff;
  border-radius: 50%;
  padding: 0px;
}

.fc-event-container .w-25 {
  width: 10px !important;
  background: red;
  float: left;
  display: inline !important;
}

/*border-selected*/
.border-selected {
  border: 2px solid red;
}

/**/
.navshad {
  -webkit-box-shadow: 1px 1px 8px 1px gray;
  -moz-box-shadow: 1px 1px 8px 1px gray;
  box-shadow: 1px 1px 8px 1px gray;

  /* the rest of your styling */
}
.navshad-top {
  box-shadow: 0 -8px 8px -9px rgb(187, 187, 187);

  /* the rest of your styling */
}

.navbar-shadow {
  box-shadow: 1px 1px 8px 1px gray;
}
.rounded-pill-abyedh {
  border-radius: 20px 20px 20px 20px;
}
.rounded-bottom-card {
  border-radius: 40px 40px 0 0;
}
.img-gray {
  filter: grayscale(100%);
}
.border-folder {
  border: 2px solid #2766ee;
}
.document-hover {
  color: #595a5c !important;
}
.document-hover:hover {
  background-color: #e8eaed !important;
  color: #2766ee !important;
}
.rounded-reciept {
  border-radius: 50px;
}
@media only screen and (max-width: 768px) {
  .rounded-reciept {
    border-radius: 10px;
  }
}
.hidden-overflow {
  overflow: hidden !important;
  padding: 2px !important;
}
.border-tabs {
  border: 1px solid gray !important;
}

.icon-shape {
  display: inline-flex !important;
  padding: 12px !important;
  padding-top: 12px !important;
  text-align: center;
  border-radius: 50% !important;
  align-items: center !important;
  justify-content: center !important;
}
/*may cause problemmes footeer card 
html {
  height: 100% !important;
}
body {
  min-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.footered-card{
  margin-top: auto !important;
}*/
.bg-about {
  background-image: url("https://cdn.abyedh.com/images/About/bg-about.gif");
  background-repeat: no-repeat;
  background-size: 100% 300px;
}
Input {
  text-align: right !important;
}
Dropdown {
  text-align: right !important;
}
.w-action-btn {
  width: 91% !important;
}
.shadow-bottom-card {
  box-shadow: 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0),
    0px 0 rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0.22);
}
.rounded-pill-input {
  border-radius: 50% !important;
}
.setWissard-res {
  flex-direction: column-reverse;
}
.no-menu-tabs .ui.menu {
  display: none !important;
}
.yes-menu-tabs .ui.menu {
  display: flex !important;
}
.btn-cursor {
  cursor: pointer !important;
}

.fullscreen-profile-modal-stopped {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 5000 !important;
  transform: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: rgba(0, 0, 0, 0.8) !important;
}
.floating-card {
  position: fixed;
  top: 65px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.floating-card:hover {
  transform: scale(1.1);
}
.floating-card-2 {
  position: fixed;
  bottom: 15px;
  right: 43%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.floating-card-2:hover {
  transform: scale(1.1);
}
.sticky-header-table {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

/*Icon & Icon small*/
.icon-system {
  width: 3rem;
  height: 3rem;
}
.icon-system img {
  font-size: 1.5rem;
}
.icon-system-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.icon-small {
  width: 7rem;
  height: 7rem;
}

.icon-small i {
  font-size: 3.5rem;
}

.icon-small-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.vertical-timeline-element-content {
  box-shadow: none !important;
  border: 1px solid gray;
  border-radius: 10px !important;
  padding: 5px !important;
}
.floating-card-suivie {
  position: fixed;
  bottom: 2px;
  left: -12px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background-color: #007bff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.floating-card-result-card {
  position: absolute;
  top: 2px !important;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.3s ease-in-out;
}
.gridjs table td {
  white-space: nowrap !important;
  overflow: hidden !important;
  /* text-overflow: ellipsis !important;  OUsed Old One  */
  text-overflow: clip !important; /* Optional: Add ellipsis for long content */
}

.max-height-image {
  max-height: 230px !important;
}
.gridjs-wrapper {
  border: none !important;
  box-shadow: none !important;
}
.gridjs-table {
  border: none !important;
  box-shadow: none !important;
}
.gridjs-footer {
  border: none !important;
  box-shadow: none !important;
  padding-top: 10px !important;
}
.gridjs-tr:hover {
  background-color: #1559b3 !important;
}
.tab-right {
  justify-content: flex-end !important;
}
.ltr-force {
  direction: ltr !important;
}
.main-input-costum:focus {
  border: 1px solid #e6e9ec !important;
  border-left: 0px !important;
}
.bg-retarder {
  background-color: #ad059f !important;
}
.bg-redirecter {
  background-color: #92ab03 !important;
}
.no-text-decoration {
  text-decoration: none !important;
}
.dir-ltr {
  text-align: left !important;
  direction: ltr !important;
}
.progress-bar-loading {
  height: 2.5px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  border-radius: 50% 50% 0 0 !important;
}

.progress-bar-loading-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.loader-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important; /* Make container cover the entire viewport height */
}
.loader-container-small {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Circular loader styles */
.loader-small {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #dc3545; /* Change the color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(150deg);
  }
  75% {
    transform: rotate(250deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fullscreen-modal-gouv {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  overflow-x: "hidden" !important;
  animation: modalOpenAnimation 0.2s ease-out;
  z-index: 999999588855 !important;
}
@keyframes modalOpenAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.card-header-for-profile {
  height: 110px;
  /* background-color: hsl(184, 63%, 64%); */
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0px 0px !important;
}

.sticky-div {
  position: -webkit-sticky !important;
  position: sticky !important;
}
